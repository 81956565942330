<template>
  <div class="space-basic">
    <div class="mb-2">
      <b-breadcrumb
        :items="breadcrumbItems"
      />
    </div>
    <h3>
      <span class="">{{ title.labelName }}</span> > {{ subTitle.labelName }}
    </h3>
    <b-alert
      variant="primary"
      show
      class=""
    >
      <div class="alert-body mt-1 mb-2">
        <div class="d-flex">
          <div class="">
            <b-avatar
              size="40"
              src="https://firebasestorage.googleapis.com/v0/b/protex-b9e4c.appspot.com/o/brand%2Fwoman-intro.png?alt=media&token=998c4413-ebc2-46cc-a62d-16c0565dfe32"
              variant="light-primary"
            />
          </div>
          <div class="ml-1">
            登録したサービスを確認できます。講座を編集する場合は｢詳細｣ボタンを押してください。
          </div>
        </div>
      </div>
    </b-alert>
    <hr>
    <div class="text-right">
      <b-button
        variant="primary"
        class="btn-wishlist"
        @click="add"
      >
        <feather-icon
          icon="PlusCircleIcon"
          class="mr-50"
        />
        <span>新規作成</span>
      </b-button>
    </div>
    <div
      v-if="lessonList.length === 0"
      class="mt-2"
    >
      表示できるデータはありません。
    </div>
    <div
      v-else
      class="mt-2"
    >
      <CategoryList
        :data-list="lessonList"
      />
    </div>
    <div class="text-white">
      <small>lesson registration list</small>
    </div>
  </div>
</template>

<script>
import {
  BAlert, BAvatar, BButton, BBreadcrumb,
} from 'bootstrap-vue'
import CategoryList from '@/components/teacher/lessonRegistration/components/CategoryList.vue'
import { ref, onMounted } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import useLesson from '@/components/js/compositionAPI/lesson/useLesson'

export default {
  components: {
    BAlert,
    BAvatar,
    BButton,
    CategoryList,
    BBreadcrumb,
  },
  setup() {
    const { route, router } = useRouter()
    const title = ref('')
    const subTitle = ref('')
    const dataList = ref([])
    const breadcrumbItems = ref([
      {
        text: '登録サービス一覧',
        to: { name: 'teacher-lesson' },
        icon: 'HomeIcon',
      },
      {
        text: '',
        active: true,
        key: 'subTitle',
      },
    ])
    const {
      categoryA,
      options,
      getCategoryLists,
      lessonList,
    } = useLesson()

    const add = () => {
      router.push({
        path: '/teacher/lessonregistration-edit',
        query: {
          met: 'create',
          categoryA: route.value.query.categoryA,
          categoryB: route.value.query.categoryB,
        },
      })
    }

    const fetch = async () => {
      const d = {
        categoryA: route.value.query.categoryA,
        categoryB: route.value.query.categoryB,
      }
      lessonList.value = await getCategoryLists(d)
    }

    onMounted(() => {
      title.value = categoryA.find(v => Number(v.value) === Number(route.value.query.categoryA))
      subTitle.value = options.categoryB.find(v => Number(v.value) === Number(route.value.query.categoryB))
      breadcrumbItems.value.find(v => v.key === 'subTitle').text = `${title.value.labelName} - ${subTitle.value.labelName}`
      fetch()
    })

    return {
      title,
      subTitle,
      categoryA,
      options,
      dataList,
      lessonList,
      add,
      breadcrumbItems,
    }
  },
}
</script>

<style lang="scss" scoped>
.title_ {
  font-size: 1.2rem;
  font-weight: 500;
  color: rgb(93, 83, 204);
}
.title_:hover {
  color: rgb(152, 151, 173);
}
.card_ {
  width: 100%;
  margin: 1% 0%;
}
.title_banner {
  font-size: 1.4rem;
  font-weight: 600;
  color: rgb(13, 4, 116);
  margin: 2% 0 1% 0;
}
.subtitle_banner {
  font-size: 1rem;
  color: rgb(13, 4, 116);
}
.commentSpace {
  height: 140px;
}
.htmlText {
  font-size: 0.8rem;
}
</style>
