<template>
  <div>
    <b-list-group>
      <b-list-group-item
        active
      >
        <div class="d-flex justify-content-between">
          <div class="">
            講座一覧
          </div>
          <div class="">
            {{ dataList.length }} 件
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <b-list-group>
      <b-list-group-item
        v-for="(item, index) in dataList"
        :key="index"
      >
        <div class="d-flex">
          <div class="text-primary text-font-L ml-1">
            {{ item.lessonName }}
          </div>
          <div
            class="ml-2"
          >
            <b-badge
              pill
              :variant="item.scope.value === 1 ? 'light-success' : 'light-secondary'"
              class="mr-1"
            >
              {{ item.scope.labelName }}
            </b-badge>
            <b-badge
              v-if="item.isSample && item.isSample.value === 1"
              pill
              variant="light-warning"
            >
              サンプル
            </b-badge>
          </div>
        </div>
        <div class="text-right">
          <b-button
            variant="outline-primary"
            size="sm"
            @click="edit(item)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            編集
          </b-button>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import {
  BListGroup, BListGroupItem, BButton, BBadge,
} from 'bootstrap-vue'
import { useRouter } from '@core/utils/utils'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BButton,
    BBadge,
  },
  directives: {
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { router, route } = useRouter()
    const edit = item => {
      router.push({
        path: '/teacher/lessonregistration-edit',
        query: {
          categoryA: route.value.query.categoryA,
          categoryB: route.value.query.categoryB,
          met: 'edit',
          id: item.docID,
        },
      })
    }
    return {
      edit,
    }
  },
}
</script>
