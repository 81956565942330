<template>
  <ListLessonRegistration />
</template>

<script>
import ListLessonRegistration from '@/components/teacher/lessonRegistration/ListLessonRegistration.vue'

export default {
  components: {
    ListLessonRegistration,
  },
}
</script>
